
import BooksCategoriesService from "@/modules/books-categories/books-categories-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BooksCategoriesService.create(payload).then(response => {
                resolve({
                    message: "BooksCategories criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar BooksCategories",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            BooksCategoriesService.update(payload.id,payload).then(response => {
                resolve({
                    message: "BooksCategories atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar BooksCategories",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            BooksCategoriesService.remove(id).then(response => {
                resolve({
                    message: "BooksCategories removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover BooksCategories",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            BooksCategoriesService.delete(id).then(response => {
                resolve({
                    message: "BooksCategories deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar BooksCategories",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            BooksCategoriesService.get(id).then(response => {
                resolve({
                    message: "BooksCategories encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BooksCategories",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BooksCategoriesService.paginate(query).then(response => {
                resolve({
                    message: "BooksCategories encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BooksCategories",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BooksCategoriesService.all().then(response => {
                resolve({
                    message: "BooksCategories encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BooksCategories",
                    success: false,
                    error
                });
            })
        })
    }
}
